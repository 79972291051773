<template>
  <div class="container">
    <spinningwheel ref="gameWheel" v-on:wheel-stopped="gameWheelStopped" width="800" height="800">
    </spinningwheel>

    <b-sidebar right open :reduce="sidebarReduced" :can-cancel="[]">

      <section class="container" style="padding: 15px;">
        <b-field>
          <b-checkbox-button v-model="sidebarReduced" type="is-white">
            <b-icon :icon="sidebarReduced ? 'angle-double-left' : 'angle-double-right'"></b-icon>
          </b-checkbox-button>
        </b-field>

        <b-collapse :open="!sidebarReduced" animation="slide-previous">
          <b-field :label="$t('wojg.filter.packs')" grouped group-multiline :label-position="'on-border'" class="box">
            <b-field class="is-grouped">
              <b-checkbox-button v-model="hasPacksFilter" class="is-primary" style="width: 18ex">
                {{ $t('wojg.filter.allPacks') }}
              </b-checkbox-button>
            </b-field>
            <template v-for="(groupedPacks, index) in packGroups">
              <b-field v-bind:key="index" class="is-grouped">
                <template v-for="pack in groupedPacks">
                  <b-checkbox-button v-bind:key="pack.id" :native-value="pack" v-model="filter.packs" style="width: 6ex">
                    {{ pack.series.ordinal }}
                  </b-checkbox-button>
                </template>
              </b-field>
            </template>
          </b-field>

          <b-field :label="$t('wojg.filter.playerCount')" :label-position="'on-border'">
            <b-numberinput v-model="filter.playerCount" max="100"
                           controls-alignment="right" controls-position="compact">
            </b-numberinput>
          </b-field>

          <b-field :label="$t('wojg.filter.drawingAmountRequired')" :label-position="'on-border'">
            <b-select icon="pen" v-model="filter.drawing">
              <option value="any">{{ $t('wojg.filter.anyDrawing') }}</option>
              <option value="none">{{ $t('wojg.filter.noDrawing') }}</option>
              <option value="some">
                <b-icon icon="check"/>
                {{ $t('wojg.filter.someDrawing') }}
              </option>
              <option value="primarily">
                <b-icon icon="check-double"/>
                {{ $t('wojg.filter.primarilyDrawing') }}
              </option>
            </b-select>
          </b-field>

          <b-field label="Categories" :label-position="'on-border'" class="control">
            <b-taginput
                ref="categoryFilterTagInput"
                v-model="filter.categories"
                :data="autocompleteCategories"
                @typing="onAutocompleteCategory"
                autocomplete
                open-on-focus
                keep-first
                expanded>
              <template v-slot="prop">
                <b-taglist attached>
                  <b-tag :type="prop.option.filterType === 'include' ? 'is-success' : 'is-danger'">{{ prop.option.filterType }}</b-tag>
                  <b-tag type="is-primary">{{ prop.option.tag }}</b-tag>
                </b-taglist>
              </template>

              <template #selected="prop">
                <b-taglist v-for="(categoryFilter, index) in prop.tags" :key="index" attached>
                  <b-tag :type="categoryFilter.filterType === 'include' ? 'is-success' : 'is-danger'">
                    {{ categoryFilter.filterType }}
                  </b-tag>
                  <b-tag type="is-primary" closable @close="$refs.categoryFilterTagInput.removeTag(index, $event)">
                    {{ categoryFilter.tag }}
                  </b-tag>
                </b-taglist>
              </template>
            </b-taginput>
          </b-field>

          <b-field label="Features" :label-position="'on-border'" class="control">
            <b-taginput
                ref="featureFilterTagInput"
                v-model="filter.features"
                :data="autocompleteFeatures"
                @typing="onAutocompleteFeature"
                autocomplete
                open-on-focus
                keep-first
                expanded>
              <template v-slot="prop">
                <b-taglist attached>
                  <b-tag :type="prop.option.filterType === 'include' ? 'is-success' : 'is-danger'">
                    {{ prop.option.filterType }}
                  </b-tag>
                  <b-tag type="is-info">
                    {{ prop.option.tag }}
                  </b-tag>
                </b-taglist>
              </template>

              <template #selected="prop">
                <b-taglist v-for="(featureFilter, index) in prop.tags" :key="index" attached>
                  <b-tag :type="featureFilter.filterType === 'include' ? 'is-success' : 'is-danger'">
                    {{ featureFilter.filterType }}
                  </b-tag>
                  <b-tag type="is-info" closable @close="$refs.featureFilterTagInput.removeTag(index, $event)">
                    {{ featureFilter.tag }}
                  </b-tag>
                </b-taglist>
              </template>
            </b-taginput>
          </b-field>

        </b-collapse>
      </section>
    </b-sidebar>

    <template>
      <section>
        <b-tabs size="is-large" class="block">
          <b-tab-item label="Games" icon="gamepad">
            <GameTable :data="allGames" :checked-rows.sync="wheelGames" checkable/>
          </b-tab-item>
          <b-tab-item label="History" icon="history">
            <b-button @click="history = []">Clear History</b-button>
            <GameTable :data="history"/>
          </b-tab-item>
        </b-tabs>
      </section>
    </template>

  </div>
</template>

<script>
// component imports
import GameTable from './components/GameTable';

// wheel imports
import spinningwheel from 'wheel-of-names/static/spinningwheel';
import WheelConfig from "wheel-of-names/static/WheelConfig";

import {GameService, GameFilter, TagFilter, TagFilterType, allGames, allSeries, allPacks} from './data/GameService.js'

export default {
  name: 'App',
  components: {
    spinningwheel,
    GameTable
  },
  async mounted() {
    const wheelConfig = new WheelConfig(this.$t('common.We have a winner!'));
    wheelConfig.names = this.wheelGameTitles;

    // TODO bit of a hack here. Maybe submit a PR to wheel-of-names or replace it with different implementation.
    wheelConfig.pictureType = 'uploaded';
    wheelConfig.customPictureDataUri = 'https://jackboxgames.b-cdn.net/wp-content/uploads/2019/05/cropped-Jackbox-Games-Icon.png';

    this.$store.commit('setWheelConfig', wheelConfig);

    this.wheelGames = this.allGames;
  },
  data() {
    return {
      allGames,
      allSeries,
      allPacks,

      gameService: new GameService(),
      filter: new GameFilter(),

      sidebarReduced: false,

      wheelGames: [],
      autocompleteCategories: [],
      autocompleteFeatures: [],
      history: []
    };
  },
  methods: {
    pickGameFromSeries(series) {
      // TODO add a series.games property computed in GameService.js
      const candidateGames = this.gameService.filterGames(this.filter, this.wheelGames,
          (game) => game.series && game.series.id === series.id );
      return candidateGames[Math.floor(Math.random() * candidateGames.length)];
    },
    gameWheelStopped(winningTitle) {
      // TODO configurable series fairness
      // If the game is part of a series, pick a game from among the series.
      const winningSeries = this.allSeries.find(series => series.title === winningTitle);
      const winningGame = winningSeries ? this.pickGameFromSeries(winningSeries)
          : this.allGames.find(game => game.title === winningTitle);

      const winningPack = this.allPacks.find(pack => pack.gameIds.includes(winningGame.id));
      this.$buefy.dialog.confirm({
        title: this.$t('common.We have a winner!'),
        // TODO i18n fault
        message: `Game: ` + winningGame.title + ', Pack: ' + winningPack.title,
        cancelText: 'Cancel',
        // TODO ideally Steam Icon should be on the confirm button, but maybe need a custom dialog to do that.
        hasIcon: true,
        icon: 'steam',
        iconPack: 'fab',
        confirmText: 'Launch in Steam',
        type: 'is-success',
        onConfirm: () => window.open('steam://run/' + winningPack.steamId, '_blank')
      });

      // TODO make this behavior configurable
      // remove winningGame from the wheel
      const winningGameIdx = this.wheelGames.findIndex(game => winningGame.id === game.id);
      if (winningGameIdx !== -1) {
        this.wheelGames.splice(winningGameIdx, 1);
      } else {
        console.log(`failed to remove ${winningGame.title} from wheelGames`);
      }

      this.history.push(winningGame);
    },

    onAutocompleteCategory(text) {
      this.autocompleteCategories = this.categoryTagFilters.filter((tagFilter) => {
        return tagFilter.tag.toLowerCase().indexOf(text.toLowerCase()) >= 0
      })
    },
    onAutocompleteFeature(text) {
      this.autocompleteFeatures = this.featureTagFilters.filter((tagFilter) => {
        return tagFilter.tag.toLowerCase().indexOf(text.toLowerCase()) >= 0
      })
    }

  },
  computed: {
    hasPacksFilter: {
      get: function() {
        return this.filter.packs.length !== 0 ;
      },
      set: function() {
        this.filter.packs = [];
      }
    },
    wheelGameTitles: function() {
      // TODO configurable series fairness
      return [...new Set(this.wheelGames.map(game => {
        if (!game.series) {
          return game.title;
        }

        return game.series.title;
      }))];
    },
    categoryTagFilters: function() {
      return [...new Set(this.allGames.flatMap(game => game.categories))]
          .flatMap(category => [
              new TagFilter(TagFilterType.INCLUDE, category),
              new TagFilter(TagFilterType.EXCLUDE, category)
          ]);
    },
    featureTagFilters: function() {
      return [...new Set(this.allGames.flatMap(game => game.features))]
          .flatMap(feature => [
            new TagFilter(TagFilterType.INCLUDE, feature),
            new TagFilter(TagFilterType.EXCLUDE, feature)
          ]);
    },
    packGroups: function() {
      let result = [];
      for (let [index, pack] of this.allPacks.entries()) {
        const bucket = Math.floor(index  / 3);
        if (result[bucket] == null) {
          result[bucket] = []
        }
        result[bucket].push(pack);
      }
      return result;
    }
  },
  watch: {
    wheelGameTitles: function(newTitles /*, oldTitles */) {
      this.$store.state.wheelConfig.names = newTitles;
    },
    // Update wheelGames as the filter is modified.
    filter: {
      handler() {
        this.wheelGames = this.gameService.filterGames(this.filter);
      },
      deep: true
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
