import allGames from '@/data/games.json'
import allPacks from '@/data/packs.json'
import allSeries from '@/data/series.json'

const seriesById = allSeries.reduce((result, series) => {
    result[series.id] = series;
    return result;
}, {});

const packsByGameId = allPacks.reduce((result, pack) => {
    pack.gameIds.forEach(gameId => result[gameId] = pack);
    pack.games = [];
    return result;
}, {});

allGames.forEach(game => {
    const pack = packsByGameId[game.id];
    if (pack) {
        pack.games.push(game);

        game.pack = pack;
    }

    if (game.seriesEntry) {
        game.series = seriesById[game.seriesEntry.seriesId];
    }
});

const TagFilterType = Object.freeze({
    INCLUDE: 'include',
    EXCLUDE: 'exclude'
});

class TagFilter {
    constructor(filterType, tag) {
        const filterTypes = Object.values(TagFilterType);
        if (!(filterTypes.includes(filterType))) {
            throw `Unknown filterType: ${filterType}, expected ${filterTypes}`;
        }

        this.filterType = filterType;
        this.tag = tag;
    }

    static toTagFilterTypeMap(tagFilters) {
        return tagFilters.reduce((result, tagFilter) => {
            result[tagFilter.filterType].push(tagFilter.tag)
            return result;
        }, {include: [], exclude: []});
    }
}

class GameFilter {
    constructor() {
        this.packs = [];
        this.playerCount = null;

        this.drawing = 'any';

        this.categories = [];
        this.features = [];
    }
}

class GameService {

    // eslint-disable-next-line no-unused-vars
    filterGames(filter, games = allGames, predicate = (game) => true) {
        const playerCountFilter = filter.playerCount;
        const packFilterGameIds = filter.packs.flatMap(pack => pack.gameIds);

        const {
            include: includeCategories,
            exclude: excludeCategories
        } = TagFilter.toTagFilterTypeMap(filter.categories);

        const {
            include: includeFeatures,
            exclude: excludeFeatures
        } = TagFilter.toTagFilterTypeMap(filter.features);

        return games
            .filter(game => {
                if (packFilterGameIds.length !== 0 && !packFilterGameIds.includes(game.id)) {
                    return false;
                }

                if (playerCountFilter != null
                    && (playerCountFilter < game.minPlayers || playerCountFilter > game.maxPlayers)) {
                    return false;
                }

                // category filters
                if (includeCategories.length !== 0 && game.categories.length !== 0
                    && !includeCategories.every(category => game.categories.includes(category))) {
                    return false;
                }
                if (excludeCategories.length !== 0 && game.categories.length !== 0
                    && excludeCategories.find(category => game.categories.includes(category))) {
                    return false;
                }

                // feature filters
                if (includeFeatures.length !== 0 && game.features.length !== 0
                    && !includeFeatures.every(feature => game.features.includes(feature))) {
                    return false;
                }
                if (excludeFeatures.length !== 0 && game.features.length !== 0
                    && excludeFeatures.find(feature => game.features.includes(feature))) {
                    return false;
                }

                // noinspection RedundantIfStatementJS
                if (filter.drawing !== 'any' && filter.drawing !== game.drawing) {
                    return false;
                }

                return predicate(game);
            });
    }
}

export {allGames, allPacks, allSeries, GameService, TagFilter, TagFilterType, GameFilter};