import Vue from 'vue'
import VueI18n from 'vue-i18n';

import store from 'wheel-of-names/static/store.js';

import App from './App.vue'
import Path from "wheel-of-names/static/Path";

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.config.productionTip = false

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: new Path(location).locale,
  // TODO externalize
  messages: {
    "en": {
      "wojg": {
        "filter": {
          "title": "Game Filter",
          "packs": "Packs",
          "playerCount": "Player Count",
          "familyModeOnly": "Family Mode Only",
          "extendedTimersOnly": "Extended Timers Only",
          "audienceOnly": "Allows Audience Only",
          "drawingAmountRequired": "Drawing Required",
          "anyDrawing": "Any",
          "noDrawing": "None",
          "someDrawing": "Some",
          "primarilyDrawing": "Primarily",
          "allPacks": "All"
        }
      },
      // TODO pull these in from wheel-spinner/static/locales
      "spinningwheel": {
        "Click to spin": "Click to Spin",
        "or press ctrl+enter": "Shortcut: Control-Enter"
      },
      "common": {
        "We have a winner!": "The Wheel hath spoken!"
      }
    }
  }
})

Vue.use(Buefy, {defaultIconPack: 'fas'})

new Vue({
  render: h => h(App),
  i18n,
  store
}).$mount('#app')
