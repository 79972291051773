<template>
  <!-- pass props from caller to b-table so we don't have to implement two-way sync of checked-rows -->
  <b-table v-bind="$attrs" v-on="$listeners" striped narrowed>
    <template #empty>
      <div class="has-text-centered">No games.</div>
    </template>

    <b-table-column field="title" label="Title" v-slot="props">
      {{ props.row.title }}
    </b-table-column>

    <b-table-column field="players" label="Players" v-slot="props">
      {{ props.row.minPlayers }}-{{ props.row.maxPlayers }}
    </b-table-column>

    <b-table-column field="drawing" label="Drawing" v-slot="props">
      <b-icon v-if="props.row.drawing !== 'none'"
              :icon="props.row.drawing === 'primarily' ? 'check-double' : 'check'"/>
    </b-table-column>

    <b-table-column field="categories" label="Categories" v-slot="props">
      <b-taglist v-if="props.row.categories">
        <template v-for="category in props.row.categories">
          <b-tag v-bind:key="category" type="is-success">{{ category }}</b-tag>
        </template>
      </b-taglist>
    </b-table-column>

    <b-table-column field="features" label="Features" v-slot="props">
      <b-taglist v-if="props.row.features">
        <template v-for="feature in props.row.features">
          <b-tag v-bind:key="feature" type="is-info">{{ feature }}</b-tag>
        </template>
      </b-taglist>
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: 'game-table',
  inheritAttrs: false
}
</script>